<template>
    <div class="page-header clearfix">
        <div class="fl hidden-sm-and-up">
            <div class="menu-btn" @click="drawerShow">
                <i class="el-icon-s-unfold"></i>
            </div>
        </div>
        <router-link to="/" class="logo fl">
            <!-- <img src="@/assets/img/logo2.png" /> -->
            <span class="logo-name">心安生命后台管理系统</span>
        </router-link>
        
        <el-dropdown v-if="$store.state.userInfo" class="fr" @command="handleCommand">
            <span class="el-dropdown-link">
                {{$store.state.userInfo.name}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item icon="el-icon-edit-outline">修改密码</el-dropdown-item> -->
                <el-dropdown-item icon="el-icon-switch-button" command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-drawer
            :visible.sync="drawer"
            :with-header="false"
            size="225px"
            custom-class="menu-drawer hidden-sm-and-up"
            ref="drawer"
            direction="ltr"
        >
            <el-aside :style="`height:auto;width:auto`">
                <page-menu :isCollapse="false" :menuWidth="225"></page-menu>
            </el-aside>
        </el-drawer>
    </div>
</template>
<script>
import PageMenu from "./components/menu";
export default {
    name: "pageHeader",
    components: { PageMenu },
    data() {
        return {
            drawer: false,
            winHeight:'100vh'
        };
    },
    mounted(){
        const _this = this;

        window.onresize = ()=>{
            const winWidth = window.innerWidth || document.body.clientWidth;
            if( winWidth >= 768 ){
                //_this.drawer = false
                _this.$refs.drawer.closeDrawer()
            }
        }
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route() {
            this.$refs.drawer.closeDrawer();
        },
    },
    methods:{
        drawerShow(){
            this.drawer = true;
            this.winHeight = document.body.clientHeight || window.screen.height
        },
        handleCommand ( command ) {
            // 退出登录
            if( command === 'logout' ){
                this.$request({
                    url:'/api/ht/user/loginout',
                    method:'POST'
                }).then(res => {
                    const{ state,errmsg } = res.data
                    if(!state) return this.$message.error(errmsg || '操作失败');
                    this.$store.commit('logOut')
                    this.$router.push({path:'/login'})
                })
            }
            
        }
    }
};
</script>
<style lang="scss" scoped>
.page-header {
    background: #fff;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    .logo {
        height: 100%;
        color: $--color-theme;
        margin-left: 54px;
        img{
            width: 26px;
            // background: #fff;
            // border-radius: 4px;
        }
        .logo-name {
            font-weight: 500;
            font-size: 16px;
            // vertical-align: middle;
            // display: inline-block;
            // margin-left: 5px;
        }
    }
    // .search-box {
    //     margin-left: 50px;
    //     overflow: hidden;
    //     max-height: 100%;
    //     .el-input{
    //         width: 300px;
    //     }
    // }
    // .el-dropdown{
    //     .el-dropdown-link{
    //         color: #ffffff;
    //     }
    // }
    .fl {
        .menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px #fff solid;
            // color: #fff;
            text-align: center;
            width: 20px;
            margin: 20px 10px 15px 0px;
            height: 20px;
            line-height: 20px;
            i {
                font-size: 18px;
            }
        }
    }
    ::v-deep.menu-drawer {
        background:#2c3e51;
        .el-aside{
            height: 100vh;
        }
    }
}
@media screen and (max-width: 768px) {
    .page-header{
        .logo{
            // display: none;
            margin-left: 0;
            .logo-name{
                font-size: 14px;
            }
        }
        // .search-box {
        //     margin-left: 0;
        //     .el-input{
        //         width: auto;
        //     }
        // }
    }
}

</style>