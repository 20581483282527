<template>
    <div class="page-sidebar">
        <div class="page-sidebar-box hidden-xs-only">
            <!-- <div class="clearfix"> -->
                <router-link v-if="!isCollapse" to="/" class="logo">
                    心安生命后台管理系统
                </router-link>
                <div class="sidebar-toggler" :style="`width:${menuWidth}px`">
                    <span @click="isCollapse = !isCollapse">
                        <i v-if="!isCollapse" class="el-icon-s-fold"></i>
                        <i v-else class="el-icon-s-unfold"></i>
                    </span>
                </div>
            <!-- </div> -->
            <page-menu :menuWidth="menuWidth" :isCollapse="isCollapse"></page-menu>
        </div>
    </div>
</template>
<script>
import PageMenu from './components/menu';
export default {
    components:{PageMenu},
    data() {
        return {
            isCollapse: false,
            menuWidth: 236
        };
    },
    watch: {
        isCollapse(flag){
            this.menuWidth = !flag ? 236 : 64;
        }
    },
    mounted() {
        const _this = this;

        const _width = window.innerWidth || document.body.clientWidth;
        if( _width < 1200 ){
            this.isCollapse = true
        }

        window.onresize = ()=>{
            const winWidth = window.innerWidth || document.body.clientWidth;
            _this.isCollapse = winWidth < 1200 ? true : false;
        }
    }
};
</script>
<style lang="scss" scoped>
.page-sidebar {
    background: #2c3e51;
    position: relative;
    .page-sidebar-box {
        margin-top: -60px;
        background: #2c3e51;
        padding-bottom: 32px;
        .logo{
            display: block;
            padding-left: 20px;
            height: 60px;
            line-height: 60px;
            color: $--color-theme;
            font-weight: 500;
            font-size: 16px;
            border-bottom: 1px #444 solid;
            // img{
            //     width: 26px;
            //     vertical-align: -4px;
            //     // background: #fff;
            //     // border-radius: 4px;
            // }
        }
        .sidebar-toggler {
            position: fixed;
            left: 0;
            bottom: 0;
            text-align: center;
            // width: 100%;
            background: #333;
            z-index: 100;
            span {
                display: block;
                // background: #c8ebdf;
                color: $--color-font-gray;
                // margin: 8px 16px;
                font-size: 18px;
                // padding: 4px 5px 4px 0;
                padding: 2px;
                cursor: pointer;
                color: #fff;
                // border-radius: 0 16px 16px 0;
                // border:1px #c8ebdf solid;
            }
        }
    }
}
</style>