<template>
    <div class="page-menu">
        <el-menu
            :collapse="isCollapse"
            background-color="#2c3e51"
            text-color="#fff"
            active-text-color="#c99c6d"
            unique-opened
            :style="`width:${menuWidth}px`"
            :collapse-transition="false"
            router
            :default-active="setMenuActive()"
        >
            <template v-for="(item,index) in menuList">
                <template v-if="item.child && item.child.length > 0">
                    <el-submenu :index="`${item.value}`" :key="index">
                        <template slot="title">
                            <i :class="[item.icon]"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <el-menu-item
                            v-for="(subItem, subIndex) in item.child"
                            :key="subIndex"
                            :index="`${subItem.value}`"
                            >{{ subItem.name }}
                        </el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="`${item.value}`" :key="item.value">
                        <i :class="[item.icon]"></i>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    name: "pageMenu",
    props: {
        menuWidth: {
            type: Number,
            default: 0,
        },
        isCollapse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menusIcon: {
                用户管理: 'el-icon-setting',
                商品管理: 'el-icon-goods',
                商家管理: 'el-icon-office-building',
                点灯记录: 'el-icon-magic-stick',
                礼金管理: 'el-icon-money',
                讣告管理: 'el-icon-tickets',
                客户管理: 'el-icon-user',
                其他: 'el-icon-monitor',
            },
        };
    },
    computed: {
        menuList() {
            // let _defaultMenus = [{
            //     name: '用户管理',
            //     link: '/system/user/list',
            // }
            // ,{
            //     name: '商品管理',
            //     link: '/goods/list'
            // }
            // ,{
            //     name: '商家管理',
            //     link: '/biz/list'
            // }
            // ,{
            //     name: '点灯记录',
            //     link: '/light/list'
            // },{
            //     name: '礼金管理',
            //     link: '/lijin/list'
            // },{
            //     name: '讣告管理',
            //     link: '/fugao/list'
            // },{
            //     name: '客户管理',
            //     link: '/customer/list'
            // }]
            let _menuList = this.$store.state.menuList || [];
            let _menus = [];
            _menuList.forEach((item) => {
                item.icon = this.menusIcon[item.name] || this.menusIcon["其他"];
                _menus.push(item);
            });

            return _menus;
        },
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route() {
            this.setMenuActive();
        },
    },
    methods: {
        
        //设置菜单选择项
        setMenuActive() {
            let path = "/";
            this.menuList.forEach((menu) => {
                const { child } = menu;
                if (child && child.length > 0) {
                    child.forEach((item) => {
                        if (
                            item.value &&
                            this.$route.path.match(new RegExp(item.value, "g"))
                        ) {
                            path = item.value;
                        }
                    });
                } else {
                    if (
                        menu.value &&
                        this.$route.path.match(new RegExp(menu.value, "g"))
                    ) {
                        path = menu.value;
                    }
                }
            });
            return path;
        },
    },
};
</script>
<style lang="scss" scoped>
.el-menu {
    border: 0;
    ::v-deep i{
        color: #fff;
    }
    // ::v-deep .el-menu-item.is-active{
    //     background-color: rgb(82, 142, 240) !important;
    // }
    ::v-deep .el-submenu__title, .el-menu-item{
        height: 42px;
        line-height: 42px;
        &:hover{
            background: #333 !important;
        }
        &.is-active{
            // background: $--color-theme !important;
            background-color: #000 !important;
            i{
                color: #c99c6d;
            }
        }
    }
    ::v-deep .el-menu{
        background: #333 !important;
        .el-menu-item{
            background: none !important;
            &.is-active{
                // background: $--color-theme !important;
                background-color: #000 !important;
                ::v-deep i{
                    color: #c99c6d;
                }
            }
        }
    }
}
</style>