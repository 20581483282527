<template>
    <div class="app-wrapper">
        <el-scrollbar>
            <page-header></page-header>
            <el-container :key="$route.path">
                <page-sidebar></page-sidebar>
                <el-main class="page-main">
                    <page-breadcrumb></page-breadcrumb>
                    <router-view tag="main" />
                    <div class="copyright">©Copyright厦门智立工贸有限公司 [闽ICP备2021019497号-1]</div>
                </el-main>
            </el-container>
            <!-- <page-footer></page-footer> -->
        </el-scrollbar>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import PageHeader from "./pageHeader";
import PageSidebar from "./pageSidebar";
// import PageFooter from "./pageFooter";
import PageBreadcrumb from './pageBreadcrumb';

export default {
    components: { PageSidebar, PageHeader, PageBreadcrumb },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
// .app-wrapper{
//     height: 100%;
//     .el-scrollbar{
//         height: 100%;
//     }
// }
.el-container{
    min-height: calc(100vh - 60px);
    display: flex;
    background: #f8f8f8;
    .page-main{
        padding: 20px 20px 60px 20px;
        // margin-top: 4px;
        position: relative;
        div[tag="main"]{
            // padding: 20px 20px 80px 20px;
            padding: 20px;
            background: #fff;
        }
        .copyright{
            position: absolute;
            bottom: 0;
            padding: 10px 0;
            text-align: center;
            left: 0;
            width: 100%;
            background: #f8f8f8;
        }
    }
}
</style>