<template>
    <div class="page-breadcrumb" v-if="breadcrumbItems && breadcrumbItems.length > 0">
        <div v-if="pageTitle" class="page-title">{{ pageTitle }}</div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{path:'/'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index) in breadcrumbItems" :key="index">
                <span
                    v-if="item.redirect === 'noRedirect' || index == breadcrumbItems.length - 1"
                    class="no-redirect"
                >{{ item.meta.title }}</span>
                <router-link v-else :to="item.path">{{ item.meta.title }}</router-link>
            </el-breadcrumb-item>
        </el-breadcrumb> -->
    </div>
</template>

<script>
export default {
    name: "pageBreadcrumb",
    data() {
        return {
            breadcrumbItems: null,
            pageTitle: "",
        };
    },
    created() {
        this.getBreadcrumb();
    },
    watch: {
        $route() {
            this.getBreadcrumb();
        },
    },
    methods: {
        getBreadcrumb() {
            const matched = this.$route.matched.filter(
                (item) => item.meta && item.meta.title
            );
            this.breadcrumbItems = matched.filter(
                (item) =>
                    item.meta &&
                    item.meta.title &&
                    item.meta.breadcrumb !== false
            );
            this.pageTitle = this.$route.meta.title;
        },
    },
};
</script>

<style lang="scss" scoped>
.page-breadcrumb {
    background: #ffffff;
    padding: 20px;
    //margin: -20px -20px 20px -20px;
    margin: 0 0 4px 0;
    .page-title {
        font-size: 24px;
    }
    .el-breadcrumb {
        margin: 15px 0 0 0;
        font-size: 16px;
    }
}
</style>